// Desktop Types
export const BACK_IMAGE_CHANGE = "BACK_IMAGE_CHANGE";
export const BRIGHTNESS_CHANGE = "BRIGHTNESS_CHANGE";
export const DATE_STATUS = "DATE_STATUS";
export const TOGGLE_DROP_DOWN = "TOGGLE_DROP_DOWN";
export const BATTERY_STATUS = "BATTERY_STATUS";
export const ONLINE_STATUS = "ONLINE_STATUS";
export const NETWORK_TYPE = "NETWORK_TYPE";
export const POWER_OFF_STATUS = "POWER_OFF_STATUS";
export const RESET_TO_DEFAULT = "RESET_TO_DEFAULT";
export const FONT_STYLE_CHANGE = "FONT_STYLE_CHANGE";
export const SINGLE_CLICK_ICON_CHANGE = "SINGLE_CLICK_ICON_CHANGE";
export const TOGGLE_FULL_SCREEN = "TOGGLE_FULL_SCREEN";
export const TOGGLE_START_MENU = "TOGGLE_START_MENU";

// Activities Types
export const CREATE_ACTIVITY = "CREATE_ACTIVITY";
export const REMOVE_ACTIVITY = "REMOVE_ACTIVITY";
export const UPDATE_ZINDEX_ACTIVITY = "UPDATE_ZINDEX_ACTIVITY";
export const ACTIVITY_TOGGLE_DROP_DOWN = "ACTIVITY_TOGGLE_DROP_DOWN";
export const TOGGLE_ACTIVITY_MAXIMISE = "TOGGLE_ACTIVITY_MAXIMISE";
export const TOGGLE_LOADING_ACTIVITY = "TOGGLE_LOADING_ACTIVITY";
export const UPDATE_ACTIVITY_POSITION = "UPDATE_ACTIVITY_POSITION";
export const UPDATE_ACTIVITY_DIMENSION = "UPDATE_ACTIVITY_DIMENSION";
export const UPDATE_ACTIVITY_TRIGGER = "UPDATE_ACTIVITY_TRIGGER";
export const REMOVE_ACTIVITY_TRIGGER = "REMOVE_ACTIVITY_TRIGGER";

// File System Types
export const MAKE_DIRECTORY_IN_SYSTEM = "MAKE_DIRECTORY_IN_SYSTEM";
export const REMOVE_DIRECTORY_IN_SYSTEM = "REMOVE_DIRECTORY_IN_SYSTEM";
export const MAKE_FILE_IN_SYSTEM = "MAKE_FILE_IN_SYSTEM";
export const CHANGE_TEXT_IN_FILE = "CHANGE_TEXT_IN_FILE";
export const PREVIOUS_STATE_SET = "PREVIOUS_STATE_SET";
